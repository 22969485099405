<template>
  <div class="main_box">
    <mescroll :up="mescrollUp" :down="mescrollDown">
      <div slot="scrollList" class="main_box">
        <headSearch :search-out="headGo" />
        <!--tab 导航-->
        <div class="must_buy_tab">
          <van-tabs
            v-model="active"
            title-active-color="#F30002"
            title-inactive-color="#141F33"
            color="red"
            line-width="76px"
            line-height="0"
            @change="changeJdTab"
          >
            <van-tab v-for="(item,index) in dataTabList" :key="index" :name="item.id" :title="item.title" />
          </van-tabs>
        </div>
        <!--img banner-->
        <div class="must_buy_img">
          <van-swipe :autoplay="3000">
            <van-swipe-item v-for="(image, index) in bannerImg" :key="index" @click="mustBuyLink">
              <img v-lazy="image.url">
            </van-swipe-item>
          </van-swipe>
        </div>

        <!--list 列表-->
        <div v-for="(item,index) in listData" :key="index" class="must_buy_list">
          <div v-for="(buy,i) in item.goodsInfo" :key="i" class="must_list">
            <div class="must_list_img">
              <!-- <img :src="buy.goodCover" alt=""> -->
              <imgLoading :src="buy.goodCover" />
            </div>
            <div class="must_list_son">
              <div class="must_list_name">{{ buy.goodName }}</div>
              <div class="must_list_bg">
                <img class="must_list_ico" src="../../assets/images/feng-ye.png" alt="">
                <span>成长值</span>
                <span>{{ buy.growthValue }}</span>
              </div>
              <div class="must_list_jg">
                <span class="must_list_isprice">¥</span>
                <span class="must_list_vipPrice">{{ buy.goodVipPrice }}</span>
                <span class="must_buy_goodPrice">¥{{ buy.goodPrice }}</span>
              </div>
              <div class="must_list_btn" @click="btnLinkGo(buy)">立即购买</div>
            </div>
          </div>
        </div>
      </div>
    </mescroll>
  </div>
</template>

<script>
import headSearch from '@/component/head/search'
import { mustBuy } from '@/services/api'
import { newActivityList } from '@/services/activity'
import mescroll from '@/component/fresh_scroll/mescroll'
import imgLoading from '@/component/imgLoading'

import { Cell, Lazyload, List, Search, Swipe, SwipeItem, Tab, Tabs, Toast } from 'vant'
import Vue from 'vue'

Vue.use(Tab).use(Tabs).use(Toast).use(List).use(Cell).use(Search).use(Swipe).use(SwipeItem).use(Lazyload)

export default {
  name: 'MustBuy',
  components: {
    imgLoading,
    mescroll,
    headSearch
  },
  data() {
    return {
      headGo: {
        isShowSearch: false, // 输入框 title 控制
        isShowReturn: true, // 返回 上一页 显隐
        bgColor: '#fff', // 背景颜色
        titleData: document.title,
        callbackGo: this.callbackGo
      },
      dataTabList: '', // tab list
      active: '',
      bannerImg: '', // img
      listData: '', // list
      tabId: '328', // tab id

      mescrollDown: {
        use: true,
        // top:'50px',
        bottom: '0'
      },
      mescrollUp: {
        initCallBack: this.mescrollInit,
        callback: this.upCallback, // 上拉回调,此处可简写; 相当于 callback: function (page, mescroll) { getListData(page); }
        page: {
          num: 0, // 当前页码,默认0,回调之前会加1,即callback(page)会从1开始
          size: 10 // 每页数据的数量
        },
        noMoreSize: 5, // 如果列表已无数据,可设置列表的总数量要大于等于5条才显示无更多数据;避免列表数据过少(比如只有一条数据),显示无更多数据会不好看
        lazyLoad: {
          use: true // 是否开启懒加载,默认false
        }
      }

    }
  },

  mounted() {
    this.gitMustBuy()
    this.gitMustList()
  },
  methods: {
    callbackGo() {
      this.$router.go(-1)
    },
    mescrollInit(data) {
      this.mescroll = data
    },
    // 上拉回调 page = {num:1, size:10}; num:当前页 ,默认从1开始; size:每页数据条数,默认10
    upCallback(page, mescroll) {
      // 接口请求数据
      this.getListDataFromNet(
        this.pdType,
        page.num,
        page.size,
        (arr) => {
          // 如果是第一页需手动制空列表
          if (page.num === 1) this.listData = []
          // 把请求到的数据添加到列表
          this.listData = this.listData.concat(arr)
          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            mescroll.endSuccess(arr.length)
          })
        },
        () => {
          // 联网异常,隐藏上拉和下拉的加载进度
          mescroll.endErr()
        }
      )
    },
    getListDataFromNet: function(pdType, pageNum, pageSize, successCallback, errorCallback) {
      const data = {
        uid: window.localStorage.getItem('uid'),
        categoryId: 0, // 默认 0
        id: this.tabId,
        page: pageNum,
        pageSize: pageSize
      }

      newActivityList(data).then(res => {
        successCallback(res.data)
      })
    },

    // 获取 tab list 列表
    gitMustBuy: function() {
      mustBuy().then(res => {
        if (Number(res.code) === 200) {
          this.dataTabList = res.data.activities
          // this.tabIdone =res.data.activities[0].id
          this.bannerImg = res.data.banner
        }
      })
    },
    // tab 切换id
    changeJdTab: function(index) {
      if (this.tabId !== index) {
        this.tabId = index
        this.listData = [] // 在这里手动置空列表,可显示加载中的请求进度
        this.mescroll.resetUpScroll() // 刷新列表数据
      }

      this.gitMustList()
    },
    // 获取 list 列表
    gitMustList: function() {
      const data = {
        uid: window.localStorage.getItem('uid'),
        id: this.tabId,
        categoryId: 0, // 默认 0
        page: this.page,
        pageSize: this.pageSize
      }
      newActivityList(data).then(res => {
        if (Number(res.code) === 200) {
          this.listData = res.data
        }
      })
    },
    // 点击跳转 详情
    btnLinkGo: function(item) {
      // 暂时跳到自营 跟app 保持一致
      this.$router.push({
        path: '/goodsDetailZY',
        query: { goodsId: item.goodId, skuid: item.productSkuId }
      })
    },
    // 点击 banner图 跳转
    mustBuyLink: function() {
      this.$router.push({
        path: '/MustBuy',
        query: ''
      })
    }
  }
}

</script>

<style lang="less" scoped>
.main_box {
  background: #f6f6f6;

  .must_buy_tab {
    background: #fff;
  }

  .must_buy_img {
    width: 346px;
    margin: 12px auto;
    height: 146px;
    border-radius: 6px;
    overflow: hidden;
  }

  .must_buy_list {
    width: 346px;
    margin: 12px auto 12px;
    overflow: hidden;

    .must_list_img {
      width: 169px;
      height: 169px;
      border-radius: 6px;
      overflow: hidden;

      .must_img {
        width: 100%;
        height: 100%;
      }
    }

    .must_list {
      width: 166px;
      height: 305px;
      background: #fff;
      float: left;
      margin: 6px 12px 6px 0;
      border-radius: 6px;

      .must_list_son {
        margin: 8px;

        .must_list_name {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          line-height: 18px;
          text-align: left;
          height: 36px;
        }

        .must_list_bg {
          display: table;
          min-width: 90px;
          background: #000;
          border-radius: 12px;
          color: #fff;
          font-size: 10px;
          padding: 3px 14px;
          box-sizing: border-box;
          margin-top: 3px;
          text-align: left;

          .must_list_ico {
            width: 12px;
            margin-left: -7px;
            vertical-align: -4px;
            margin-right: 2px;
          }
        }

        .must_list_jg {
          text-align: left;
          margin-top: 6px;

          .must_list_isprice {
            font-size: 12px;
            color: #bf0808;
          }

          .must_list_vipPrice {
            font-size: 18px;
            color: #bf0808;
            font-weight: 700;
            margin-right: 6px;
          }

          .must_buy_goodPrice {
            color: #999999;
            text-decoration: line-through;
            font-size: 10px;
          }
        }

        .must_list_btn {
          width: 100%;
          height: 36px;
          margin: 0 auto;
          background: #ff5e00;
          border-radius: 6px;
          color: #fff;
          text-align: center;
          line-height: 36px;
          font-size: 14px;
        }
      }
    }

    .must_list:nth-child(2n) {
      margin-right: 0px;
    }

    .must_list_img {
      width: 100%;
    }
  }
}

</style>
